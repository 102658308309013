import { createSlice } from '@reduxjs/toolkit';
import image1 from '../assets/getsalesforcesessionidusingchrome-Image.webp'
import image2 from '../assets/calculate-percentage-for-column-by-row-and-row-by-column-in-salesforce-report_files.webp'
import image3 from '../assets/getaccesstokenusingpostman.webp'
import image4 from '../assets/deleteclassusingworkbench.webp'
import image5 from '../assets/opnemodelpopupfromlistviewbuttoninsalesforce.webp'
import image6 from '../assets/show-lookup-combox-and-lightning-datatable-in-quick-action-panel.webp'
import image7 from '../assets/how-to-open-email-composer-using-aura-component.webp'
import image8 from '../assets/salesforce-integration-with-heorku.webp'
import image9 from '../assets/salesforce-integration-with-dialpad-image.webp'
import image10 from '../assets/howtoIntegrateSalesforceandSetup.webp'
import image11 from '../assets/singleemailmessagemethods.webp'
import image12 from '../assets/person-account-in-salesforce.webp'
import image13 from '../assets/sendgrid-integration.webp'
import image14 from '../assets/globalpay-integration-salesforce.webp'
import image15 from '../assets/bulk-api-in-salesforce-using-postman.webp'
import image16 from '../assets/selecting-multiple-records-with-pagination-in-the-quick-action-panel-in-lwc.webp'
import image17 from '../assets/google-sheet-and-salesforce-integration-using-zapier.webp'
import image18 from '../assets/set-up-for-pardot-api-calling-from-salesforce.webp'
import image19 from '../assets/poc-to-integration-ninjarmm-with-salesforce.webp'
import image20 from '../assets/integration-itglue-with-salesforce-image.webp'
import image21 from '../assets/custom-table-with-pagination-and-multiple-select-records-inline-editing-in-lwc.webp'
import image22 from '../assets/how-to-add-product-with-custom-button-using-lwc-image.webp'
import image23 from '../assets/integration-sharepoint-with-salesforce.webp'
import image24 from '../assets/how-to-get-fields-definition-of-sObject-and-get-specific-field-ID-using-REST-API.webp';
import image25 from '../assets/How-to-create-update-delete-record-and-query-in-postman-using-REST-API.webp'
import image26 from '../assets/how-to-create-duplicate-to-existing-google-sheet-using-zapier-and-create-row-in-sheet-on-opportunity-record-create.webp'
import image27 from '../assets/rollup-summary-operations-with-custom-code.webp'
import image28 from '../assets/communication-between-salesforce-flow-and-lwc.webp'
import image29 from '../assets/Google-Sheet-Integration-With-Salesforce-Using-Google-Rest-API.webp'
import image30 from '../assets/How-to-show-EmailMessage-Last-Opened-Time-on-Activity.webp'
import image31 from '../assets/How-To-Revoke-Salesforce-Org-From-Salesforce-Who-Authorized-With-VS-Code-Editor.webp'
import image32 from '../assets/Security-Level-Permission-In-Salesforce.webp'
import image33 from '../assets/Get-sObject-Field-Value-Dynamically-With-Apex.webp'
import image34 from '../assets/How-to-Send-SMS-through-Twilio-API-Using-Apex-in-Salesforce.webp'
import image35 from '../assets/how-to-generate-PDF-invoice-and-send-on-email-using-web-to-lead-form-in-salesforce.webp'
import image36 from '../assets/execution-of-flow-after-insert-a-record.webp'
import image37 from '../assets/how-to-send-SMS-through-SMS.to-API-using-apex-in-salesforce.webp'
import image38 from '../assets/how-to-install-or-setup-cumulus-ci-on-your-computer.webp'
import image39 from '../assets/basics-commands-cheat-sheet-of-cumulus-ci.webp'
import image40 from '../assets/troubleshooting-API-failures-with-log-frames.webp'
import image41 from '../assets/setting-up-milestones-in-salesforce-for-effective-case-management.webp'
import image42 from '../assets/how-to-create-and-retrieve-metadata-useing-SOAP-API.webp'
import image43 from '../assets/how-to-explore-salesforce-ui-api.webp'
import image44 from '../assets/implementing-salesforce-tooling-API-techniques.webp'
import image45 from '../assets/implementing-salesforce-REST-API-techniques.webp'
import image46 from '../assets/unlocking-salesforce-metadata-management-with-metadata-service-API.webp'
import image47 from '../assets/how-to-create-header-component-in-lighting-web-component.png'
import image48 from '../assets/how-to-create-chatter-post-in-lightning-web-component.webp'
import image49 from '../assets/integration-loanpro-with-salesforce.webp'
import image50 from '../assets/developing-a-postman-like-app-using-LWC-Boost-Your-API-testing-efficiency.webp';
import image51 from '../assets/mastering-salesforce-outlook-email-sync-einstein-activity-capture-guide.webp'
import image52 from '../assets/stripe-integration-with-salesforce.webp'
import image53 from '../assets/xero-integration-with-salesforce.webp'
import image54 from '../assets/calling-asynchronous-processes-from-asynchronous-processes-in-apex-salesforce.webp'
import image55 from '../assets/time-based-flow-in-salesforce.webp'
import image56 from '../assets/sending-SMS-through-mailchimp.webp'
import image57 from "../assets/upload-image-from-postman-to-salesforce.webp"

const BLOGDATA = {
    "salesforce": [
        {
            "id": 1,
            "slug":"get-salesforce-session-id-using-chrome",
            "path":"blogs/get-salesforce-session-id-using-chrome.html",
            "title": "Get Salesforce Session Id Using Chrome",
            "date": "April 19, 2023",
            "description":"In this blog, we will learn How to get salesforce session id using chrome.",
            "image":image1,
            
        },
        {
            "id": 2,
            "slug":"calculate-percentage-for-column-by-row-and-row-by-column-in-salesforce-report_files",
            "path":"blogs/calculate-percentage-for-column-by-row-and-row-by-column-in-salesforce-report.html",
            "title": "Calculate percentage for column by row and row by column in salesforce report_files",
            "date": "April 20, 2023",
            "description":"With this blog, we will learn How we can calculate the percentage (%) with Row by Column and Column by Row in the Salesforce Report.",
            "image":image2,
            
        },
        {
            "id": 3,
            "slug":"how-to-get-access-token-in-postman",
            "path":"blogs/how-to-get-access-token-in-postman.html",
            "title": "Get access token using postman",
            "date": "April 21, 2023",
            "description":"The purpose of this tutorial is to elaborate How to get the token using Postman.",
            "image":image3,
            
        },
        {
            "id": 4,
            "slug":"delete-apex-classes-triggers-from-production-using-workbench",
            "path":"blogs/delete-apex-classes-triggers-from-production-using-workbench.html",
            "title": "Delete Apex Classes/Triggers from Production Using Workbench",
            "date": "May 03, 2023",
            "description":"This blog is explained about How to delete apex classes or triggers from Production org using workbench.",
            "image":image4,
            
        },
        {
            "id": 5,
            "slug":"how-to-open-model-or-popup-using-list-view-button-from-the-related-list-in-salesforce",
            "path":"blogs/how-to-open-model-or-popup-using-list-view-button-from-the-related-list-in-salesforce.html",
            "title": "How to Open Model/Popup using List View Button from the Related List in Salesforce",
            "date": "May 04, 2023",
            "description":"In this salesforce tutorial, we will discuss about How can we open the model or popup from list view button which are exists on the related list of an object using salesforce flow.",
            "image":image5,
            
        },
        {
            "id": 6,
            "slug":"show-lookup-combobox-and-lightning-datatable-in-quick-action-panel",
            "path":"blogs/show-lookup-combobox-and-lightning-datatable-in-quick-action-panel.html",
            "title": "Show lookup combobox and lightning datatable in quick action panel",
            "date": "May 09, 2023",
            "description":"This blog help you to how can set Lookup Combobox and Lightning Datable in QuickAction Panel. If want to this you are right place. To achieve this I have created two component in which on of them child component(named-comboxAndTableQuickAction) and other is parent component(named-parentComboWithTable).",
            "image":image6,
            
        },
        {
            "id": 7,
            "slug":"how-to-open-email-composer-using-aura-component",
            "path":"blogs/how-to-open-email-composer-using-aura-component.html",
            "title": "How to open email composer using aura component",
            "date": "May 11, 2023",
            "description":"In this salesforce blog, we will learn about How to create a screen to insert a custom object record using Lightning Web Component and How to open email action using Aura Component when we click on the button from the custom object record detail page. To achieve this requirement, let’s create three objects named Plan, Option and LineItem.",
            "image":image7,  
        },
        {
            "id": 8,
            "slug":"salesforce-integration-with-Heorku",
            "path":"blogs/salesforce-integration-with-Heorku.html",
            "title": "Salesforce integration with Heorku",
            "date": "May 12, 2023",
            "description":"In this blog, we will learn What is Heroku and How to make a connection between Salesforce and Heroku.",
            "image":image8,  
        },
        {
            "id": 9,
            "slug":"salesforce-integration-with-dialpad",
            "path":"blogs/salesforce-integration-with-dialpad.html",
            "title": "Salesforce integration with dialpad",
            "date": "May 13, 2023",
            "description":"With this salesforce tutorial, we will learn about How we can integrate Salesforce with Dialpad and How to send SMS from Salesforce using Dialpad.",
            "image":image9,  
        },
        {
            "id": 10,
            "slug":"how-to-integrate-squareUp-payments-with-salesforce",
            "path":"blogs/how-to-integrate-squareUp-payments-with-salesforce.html",
            "title": "How to integrate squareUp payments with salesforce",
            "date": "May 16, 2023",
            "description":"In this blog we will integrate Salesforce with SquareUp and we will generate an invoice link of SquareUp for a customer, using this link the customer can make the payment.",
            "image":image10,  
        },
        {
            "id": 11,
            "slug":"explore-salesforce-singleEmailMessage-class-methods",
            "path":"blogs/explore-salesforce-singleEmailMessage-class-methods.html",
            "title": "Explore salesforce singleEmailMessage class methods",
            "date": "May 19, 2023",
            "description":"By using SingleEmailMessage we can send emails. SingleEmailMessage is a class which has some important methods to send email.",
            "image":image11,  
        },
        {
            "id": 12,
            "slug":"person-account-in-salesforce",
            "path":"blogs/person-account-in-salesforce.html",
            "title": "Person account in salesforce",
            "date": "May 20, 2023",
            "description":"In this blog, we will learn about Person Account in salesforce. Let’s discuss Person Account with the help of the following document.",
            "image":image12,  
        },
        {
            "id": 13,
            "slug":"sendgrid-integration",
            "path":"blogs/sendgrid-integration.html",
            "title": "SendGrid Integration With Salesforce",
            "date": "May 22, 2023",
            "description":"SendGrid is a cloud-based SMTP provider that allows you to send email without having to maintain email servers. SendGrid manages all of the technical details, from scaling the infrastructure to ISP outreach and reputation monitoring to whitelist services and real time analytics.",
            "image":image13,  
        },
        {
            "id": 14,
            "slug":"globalpay-integration-salesforce",
            "path":"blogs/globalpay-integration-salesforce.html",
            "title": "Globalpay integration salesforce",
            "date": "May 24, 2023",
            "description":"In this blog post, we talk about integration between GlobalPay and Salesforce using Rest API in Apex.",
            "image":image14,  
        },
        {
            "id": 15,
            "slug":"bulk-api-in-salesforce-using-postman",
            "path":"blogs/bulk-api-in-salesforce-using-postman.html",
            "title": "Bulk api in salesforce using postman",
            "date": "May 30, 2023",
            "description":"In this blog we will learn about How to use Bulk API with Postman tool in salesforce. But before starting, let’s see some statements on the Bulk API in salesforce.",
            "image":image15,  
        },
        {
            "id": 16,
            "slug":"selecting-multiple-records-with-pagination-in-the-quick-action-panel-in-lwc",
            "path":"blogs/selecting-multiple-records-with-pagination-in-the-quick-action-panel-in-lwc.html",
            "title": "Selecting multiple records with pagination in the quick action panel in LWC",
            "date": "June 01, 2023",
            "description":"In this blog, we will set lightning-datatable in the quick action panel by selecting multiple records. And When the end user goes to save the record before selecting",
            "image":image16,  
        },
        {
            "id": 17,
            "slug":"google-sheet-and-salesforce-integration-using-zapier",
            "path":"blogs/google-sheet-and-salesforce-integration-using-zapier.html",
            "title": "Google Sheet and Salesforce Integration Using Zapier",
            "date": "June 07, 2023",
            "description":"In this blog post, we talk about integration between Google Sheet and Salesforce using Zapier.",
            "image":image17,  
        },
        {
            "id": 18,
            "slug":"set-up-for-pardot-api-calling-from-salesforce",
            "path":"blogs/set-up-for-pardot-api-calling-from-salesforce.html",
            "title": "Set up for pardot api calling from salesforce",
            "date": "June 15, 2023",
            "description":"In this blog post, we talk about pardot API calling from salesforce",
            "image":image18,  
        },
        {
            "id": 19,
            "slug":"poc-to-integration-ninjarmm-with-salesforce",
            "path":"blogs/poc-to-integration-ninjarmm-with-salesforce.html",
            "title": "POC to Integration NinjaRMM with  Salesforce",
            "date": "June 16, 2023",
            "description":"In this tutorial we will learn about What is NinjaRMM and How we can call the NinjaRMM API's.",
            "image":image19,  
        },
        {
            "id": 20,
            "slug":"integration-itglue-with-salesforce",
            "path":"blogs/integration-itglue-with-salesforce.html",
            "title": "Integration itglue with salesforce",
            "date": "June 17, 2023",
            "description":"In today's fast-paced digital world, effective management of customer information and seamless workflow integration are key to success",
            "image":image20,  
        },
        {
            "id": 21,
            "slug":"custom-table-with-pagination-and-multiple-select-records-inline-editing-in-lwc",
            "path":"blogs/custom-table-with-pagination-and-multiple-select-records-inline-editing-in-lwc.html",
            "title": "Custom table with pagination and multiple select records inline editing in LWC",
            "date": "June 17, 2023",
            "description":"This blog is on a custom table with pagination and you can select multiple records, checkbox will not remove after even changing the pages This blog is on a custom table with pagination and you can select multiple records, checkbox will not remove after even changing the pages of the table when you go back to the previous page checked records will exist.",
            "image":image21,  
        },
        {
            "id": 22,
            "slug":"how-to-add-product-with-custom-button-using-lwc",
            "path":"blogs/how-to-add-product-with-custom-button-using-lwc.html",
            "title": "How to Add Products with Custom Button using LWC",
            "date": "June 23, 2023",
            "description":"In Salesforce, managing opportunities efficiently is crucial for sales teams. The ability to add products quickly and seamlessly can significantly enhance the sales process. In this blog post",
            "image":image22,  
        },
        {
            "id": 23,
            "slug":"integration-sharepoint-with-salesforce",
            "path":"blogs/integration-sharepoint-with-salesforce.html",
            "title": "Integration sharepoint with salesforce",
            "date": "June 28, 2023",
            "description":"In today's fast-paced business environment, organizations are constantly seeking ways to streamline processes, enhance collaboration, and improve data management",
            "image":image23,  
        },
        {
            "id": 24,
            "slug":"how-to-get-fields-definition-of-sObject-and-get-specific-field-ID-using-REST-API",
            "path":"blogs/how-to-get-fields-definition-of-sObject-and-get-specific-field-ID-using-REST-API.html",
            "title": "How To Get Fields Definition of sObject and Get Specific Field ID Using REST API",
            "date": "July 05, 2023",
            "description":"In this blog we will discuss how to get fields definition and field id of an sobject using rest api through postman.",
            "image":image24,  
        },
        {
            "id": 25,
            "slug":"How-to-create-update-delete-record-and-query-in-postman-using-REST-API",
            "path":"blogs/How-to-create-update-delete-record-and-query-in-postman-using-REST-API.html",
            "title": "How to Create, Update, Delete Record And Query in Postman Using REST API",
            "date": "July 08, 2023",
            "description":"In this blog we will discuss how to create, update, delete, query and queryAll the records using rest api through postman.",
            "image":image25, 
        },
        {
            "id": 26,
            "slug":"How-to-clone-existing-google-sheet-and-insert-row-data-using-zapier",
            "path":"blogs/How-to-clone-existing-google-sheet-and-insert-row-data-using-zapier.html",
            "title": "How to clone existing google sheet and insert row data using zapier",
            "date": "July 10, 2023",
            "description":"In this blog we will use the Zapier tool to create duplicates of existing google sheets and when opportunity records are",
            "image":image26, 
        },
        {
            "id": 27,
            "slug":"rollup-summary-operations-with-custom-code",
            "path":"blogs/rollup-summary-operations-with-custom-code.html",
            "title": "Rollup summary operations with custom code",
            "date": "July 14, 2023",
            "description":"Salesforce is a powerful CRM platform that empowers businesses to streamline their operations, improve customer relationships, and drive growth. One of the essential features of Salesforce is Rollup Summary fields",
            "image":image27, 
        },
        {
            "id": 28,
            "slug":"communication-between-salesforce-flow-and-lwc",
            "path":"blogs/communication-between-salesforce-flow-and-lwc.html",
            "title": "Communication Between Salesforce Flow and LWC",
            "date": "July 28, 2023",
            "description":"Salesforce Lightning Web Components (LWC) is a modern, lightweight framework for building web applications on the Salesforce platform. LWC utilizes the web standards-based programming model, allowing developers to ",
            "image":image28, 
        },
        {
            "id": 29,
            "slug":"Google-Sheet-Integration-With-Salesforce-Using-Google-Rest-API",
            "path":"blogs/Google-Sheet-Integration-With-Salesforce-Using-Google-Rest-API.html",
            "title": "Google Sheet Integration With Salesforce Using Google Rest API",
            "date": "Aug 17, 2023",
            "description":"In this blog post, we will learn about integration between Google Sheet and Salesforce using Google Rest API.",
            "image":image29, 
        },
        {
            "id": 30,
            "slug":"How-to-show-EmailMessage-Last-Opened-Time-on-Activity-and-Associate-Activity-to-Account-Contact-and-Custom-Object-in-Salesforce-using-Apex",
            "path":"blogs/How-to-show-EmailMessage-Last-Opened-Time-on-Activity-and-Associate-Activity-to-Account-Contact-and-Custom-Object-in-Salesforce-using-Apex.html",
            "title": "How to show EmailMessage Last Opened Time on Activity and Associate Activity to Account Contact and Custom Object in Salesforce using Apex",
            "date": "Aug 24, 2023",
            "description":"In this blog post, we will learn about show emailMessage open time and link email message as activity on account, contact and custom object using apex code.",
            "image":image30, 
        },
        {
            "id": 31,
            "slug":"How-To-Revoke-Salesforce-Org-From-Salesforce-Who-Authorized-With-VS-Code-Editor",
            "path":"blogs/How-To-Revoke-Salesforce-Org-From-Salesforce-Who-Authorized-With-VS-Code-Editor.html",
            "title": "How To Revoke Salesforce Org From Salesforce Who Authorized With VS Code Editor",
            "date": "Aug 25, 2023",
            "description":"In this blog post, we will learn about how to revoke the salesforce org which is authorized by VS Code.",
            "image":image31, 
        },
        {
            "id": 32,
            "slug":"Security-Level-Permission-In-Salesforce",
            "path":"blogs/Security-Level-Permission-In-Salesforce.html",
            "title": "Security Level Permission In Salesforce",
            "date": "Sept 6, 2023",
            "description":"In Salesforce, Security Settings refer to a set of configuration options and controls that allow administrators to define and customize various aspects of security within the Salesforce platform. ",
            "image":image32, 
        },
        {
            "id": 33,
            "slug":"Get-sObject-Field-Value-Dynamically-With-Apex",
            "path":"blogs/Get-sObject-Field-Value-Dynamically-With-Apex.html",
            "title": "Get sObject Field Value Dynamically With Apex",
            "date": "Sept 15, 2023",
            "description":"'Dynamically getting' in Apex refers to the process of accessing or retrieving data, objects, or fields at runtime based on conditions or variables rather than hardcoding them into your code. ",
            "image":image33, 
        },
        {
            "id": 34,
            "slug":"How-to-Send-SMS-through-Twilio-API-Using-Apex-in-Salesforce",
            "path":"blogs/How-to-Send-SMS-through-Twilio-API-Using-Apex-in-Salesforce.html",
            "title": "How to Send SMS through Twilio API Using Apex in Salesforce",
            "date": "Sept 15, 2023",
            "description":"In today's digital world, communication is key, and Salesforce is at the forefront of helping businesses manage and automate their communication processes. With the integration of Twilio's powerful SMS API, you can seamlessly send SMS messages directly from Salesforce using Apex,",
            "image":image34, 
        },
        {
            "id": 35,
            "slug":"How to generate PDF invoice and send on email using web to lead form in salesforce",
            "path":"blogs/how-to-generate-PDF-invoice-and-send-on-email-using-web-to-lead-form-in-salesforce.html",
            "title": "How to generate PDF invoice and send on email using web to lead form in salesforce",
            "date": "Sept 23, 2023",
            "description":"Efficiently managing invoices is a crucial aspect of any business, and Salesforce is a powerful platform for streamlining various aspects of sales and customer relationship management. One common requirement for businesses is the ability to send invoices to leads or customers as PDFs directly from Salesforce.",
            "image":image35, 
        },
        {
            "id": 36,
            "slug":"execution-of-flow-after-insert-a-record",
            "path":"blogs/execution-of-flow-after-insert-a-record.html",
            "title": "Execution of flow After Insert A Record",
            "date": "Sept 28, 2023",
            "description":"The order of execution for a flow in Salesforce outlines the sequence in which different operations and actions occur when a flow is triggered. This order helps ensure that the flow behaves as expected and produces the desired outcomes.",
            "image":image36, 
        },
        {
            "id": 37,
            "slug":"how-to-send-SMS-through-SMS.to-API-using-apex-in-salesforce",
            "path":"blogs/how-to-send-SMS-through-SMS.to-API-using-apex-in-salesforce.html",
            "title": "How to Send SMS through SMS.To API Using Apex in Salesforce",
            "date": "Sept 30, 2023",
            "description":"In this blog post, we will learn about how to send sms through SMS.To API using apex.",
            "image":image37, 
        },
        {
            "id": 38,
            "slug":"how-to-install-or-setup-cumulus-ci-on-your-computer",
            "path":"blogs/how-to-install-or-setup-cumulus-ci-on-your-computer.html",
            "title": "How to install/setup Cumulus CI on your computer",
            "date": "Oct 03, 2023",
            "description":"CumulusCI helps development teams build great applications on the Salesforce platform by automating org setup, testing, and deployment.",
            "image":image38,
        },
        {
            "id": 39,
            "slug":"basic-commands-cheat-sheet-of-cumulus-ci",
            "path":"blogs/basic-commands-cheat-sheet-of-cumulus-ci.html",
            "title": "Basic command cheat sheet of cumulus ci",
            "date": "Oct 05, 2023",
            "description":"CumulusCI (Cumulus Continuous Integration) is an open-source framework developed by Salesforce.org (formerly the Salesforce Foundation) to automate the process of building, testing",
            "image":image39,
        },
        {
            "id": 40,
            "slug":"Troubleshooting API failures with log frames",
            "path":"blogs/troubleshooting-API-failures-with-log-frames.html",
            "title": "Troubleshooting API failures with log frames",
            "date": "Oct 13, 2023",
            "description":"In the world of Salesforce development, making API calls is a routine task. These calls allow you to interact with external systems, retrieve data, and update records. However, the success of these calls is never guaranteed, as errors can occur for various reasons.",
            "image":image40,
        },
        {
            "id": 41,
            "slug":"setting-up-milestones-in-salesforce-for-effective-case-management",
            "path":"blogs/setting-up-milestones-in-salesforce-for-effective-case-management.html",
            "title": "Setting Up Milestones in Salesforce for Effective Case Management",
            "date": "Oct 18, 2023",
            "description":"In today's fast-paced business landscape, exceptional customer service is non-negotiable. To meet and exceed customer expectations, organizations turn to Salesforce, a robust CRM platform known for its extensive suite of tools. One of the standout features that ",
            "image":image41,
        },
        {
            "id": 42,
            "slug":"how-to-create-and-retrieve-metadata-using-SOAP-API",
            "path":"blogs/how-to-create-and-retrieve-metadata-using-SOAP-API.html",
            "title": "How to create and retrieve metadata using SOAP API",
            "date": "Nov 07, 2023",
            "description":"Salesforce is a powerful CRM platform that allows organizations to manage their customer relationships, sales, and other business processes efficiently. While Salesforce provides a wide range of standard objects and fields to work with, you may need to create.",
            "image":image42,
        },
        {
            "id": 43,
            "slug":"how-to-explore-salesforce-ui-api",
            "path":"blogs/how-to-explore-salesforce-ui-api.html",
            "title": "How to create salesforce ui api",
            "date": "Nov 10, 2023",
            "description":"The Salesforce User Interface (UI) API is a powerful resource that facilitates the creation of dynamic and responsive user interfaces for Salesforce applications. It provides developers with a standardized way to interact with and manipulate the UI elements of",
            "image":image43,
        },
        {
            "id": 44,
            "slug":"implementing-salesforce-tooling-API-techniques",
            "path":"blogs/implementing-salesforce-tooling-API-techniques.html",
            "title": "Implementing salesforce tooling API techniques",
            "date": "Nov 23, 2023",
            "description":"Welcome to the world of the Tooling API – where efficiency meets innovation, and every line of code becomes a pathway to enhanced success.",
            "image":image44,
        },
        {
            "id": 45,
            "slug":"implementing-salesforce-REST-API-techniques",
            "path":"blogs/implementing-salesforce-REST-API-techniques.html",
            "title": "Implementing Salesforce REST API Techniques",
            "date": "Nov 28, 2023",
            "description":"In the dynamic landscape of Salesforce development, the ability to seamlessly integrate external systems and exchange data is a cornerstone of success.",
            "image":image45,
        },
        {
            "id": 46,
            "slug":"unlocking-salesforce-metadata-management-with-metadata-service-API",
            "path":"blogs/unlocking-salesforce-metadata-management-with-metadata-service-API.html",
            "title": "Unlocking Salesforce Metadata Management with Metadata API",
            "date": "Dec 15, 2023",
            "description":"Salesforce, a powerful and flexible platform for customer relationship management (CRM), relies heavily on metadata to define and manage its data model",
            "image":image46,
        },
        {
            "id": 47,
            "slug":"how-to-create-header-component-in-lighting-web-component",
            "path":"blogs/how-to-create-header-component-in-lighting-web-component.html",
            "title": "How to create header component in Lightning Web Component",
            "date": "Dec 28, 2023",
            "description":"Today in this blog we will create a header (navbar) component. To create a component we right click and ",
            "image":image47,
        },
        {
            "id": 48,
            "slug":"how-to-create-chatter-post in-lightning-web-component",
            "path":"blogs/how-to-create-chatter-post in-lightning-web-component.html",
            "title": "How to Create Chatter Post in lightning web component",
            "date": "Jan 18, 2024",
            "description":"Here we will create chatter post component to practice the lightning web components.",
            "image":image48,
        },
        {
            "id": 49,
            "slug":"integration-loanpro-with-salesforce",
            "path":"blogs/integration-loanpro-with-salesforce.html",
            "title": "Integration loanpro with salesforce",
            "date": "Jan 20, 2024",
            "description":"Welcome to the intersection of innovation and financial prowess – welcome to the LoanPro-Salesforce integration revolution.",
            "image":image49,
        },
        {
            "id": 50,
            "slug":"developing-a-postman-like-app-using-LWC-Boost-Your-API-testing-efficiency",
            "path":"blogs/developing-a-postman-like-app-using-LWC-Boost-Your-API-testing-efficiency.html",
            "title": "Developing a Postman-like App using LWC: Boost Your API testing efficiency",
            "date": "Feb 26, 2024",
            "description":"API stands for Application Programming Interface. It is set of protocols which does enable the data to transfer on different",
            "image":image50,
        },
        {
            "id": 51,
            "slug":"mastering-salesforce-outlook-email-sync-einstein-activity-capture-guide",
            "path":"blogs/mastering-salesforce-outlook-email-sync-einstein-activity-capture-guide.html",
            "title": "Mastering salesforce outlook email sync: Einstein activity capture guide",
            "date": "March 1, 2024",
            "description":"In today's fast-paced business environment, sales teams are constantly striving to streamline their processes and maximize productivity.",
            "image":image51,
        },
        {
            "id": 52,
            "slug":"stripe-integration-with-salesforce",
            "path":"blogs/stripe-integration-with-salesforce.html",
            "title": "Stripe Integration with Salesforce",
            "date": "April 9, 2024",
            "description":"In today's digital age, where e-commerce is booming and customer relationship management is paramount, integrating payment processing platforms",
            "image":image52,
        },
        {
            "id": 53,
            "slug":"xero-integration-with-salesforce",
            "path":"blogs/xero-integration-with-salesforce.html",
            "title": "Xero Integration with Salesforce",
            "date": "April 12, 2024",
            "description":"The integration between Xero and Salesforce offers a multitude of benefits for businesses looking to streamline their operations and drive growth.",
            "image":image53,
        },
        {
            "id": 54,
            "slug":"calling-asynchronous-processes-from-asynchronous-processes-in-apex-salesforce",
            "path":"blogs/calling-asynchronous-processes-from-asynchronous-processes-in-apex-salesforce.html",
            "title": "Calling asynchronous processes from asynchronous processes in apex salesforce",
            "date": "Oct 16, 2024",
            "description":"This blog post dives into the world of asynchronous processing in Apex Salesforce",
            "image":image54,
        },
        {
            "id": 55,
            "slug":"time-based-flow-in-salesforce",
            "path":"blogs/time-based-flow-in-salesforce.html",
            "title": "Time based flow in salesforce",
            "date": "Oct 17, 2024",
            "description":"Salesforce Flow is a powerful automation tool that allows admins",
            "image":image55,
        },
        {
            "id":56,
            "slug":"sending-SMS-through-mailchimp",
            "path":"blogs/sending-SMS-through-mailchimp.html",
            "title": "Sending SMS through mailchimp",
            "date": "Oct 26, 2024",
            "description":"Mailchimp is a leading all-in-one marketing platform designed to help businesses manage their marketing efforts",
            "image":image56,
        },
        {
            "id": 57,
            "slug":"upload-image-from-postman-to-salesforce",
            "path":"blogs/upload-image-from-postman-to-salesforce.html",
            "title": "Upload Image from Postman to Salesforce",
            "date": "Jan 3, 2025",
            "description":"Salesforce provides a powerful toolset for developers to manage and enforce",
            "image":image57,
        }
    ]
}

BLOGDATA.salesforce.sort((a, b) => 
    b.id - a.id
)

const blogSlice = createSlice({
    name:"blog",
    initialState : {data: BLOGDATA}, 
    
})

export const blogActions = blogSlice.actions

export default blogSlice;